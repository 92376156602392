import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "./assets/css/public.scss";
import animate from "animate.css";
import less from "less";
// import VueAwesomeSwiper from 'vue-awesome-swiper'
import "swiper/swiper-bundle.css";
Vue.config.productionTip = false;

// 雷达图
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import { RadarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
echarts.use([
  TitleComponent,
  LegendComponent,
  TooltipComponent,
  RadarChart,
  CanvasRenderer,
]);

Vue.use(Antd);
Vue.use(animate);
Vue.use(less);

import NProgress from "nprogress"; //顶部进度条
import "nprogress/nprogress.css";
import "ant-design-vue/dist/antd.less";
Vue.prototype.$EventBus = new Vue();
// Vue.use(VueAwesomeSwiper)

router.beforeEach((to, from, next) => {
  // console.log(to);
  NProgress.start();
  // if(to.meta.login && store.state.Public.Token === ''){
  //   next({path:'/login'});
  // }else{
  next();
  // }
});

router.afterEach(() => {
  NProgress.done();
  window.scrollTo(0, 0);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
