import Vue from "vue";
import VueRouter from "vue-router";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("@/views/index/index.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        meta: {
          title: "首页",
        },
        component: () => import("@/views/index/page/Home.vue"),
      },
      {
        path: "/list",
        name: "list",
        meta: {
          title: "产品列表",
        },
        component: () => import("@/views/index/page/list.vue"),
      },
      {
        path: "/order",
        name: "order",
        meta: {
          title: "我的订单",
          login: true,
        },
        component: () => import("@/views/index/page/order.vue"),
      },
      {
        path: "/check",
        name: "check",
        meta: {
          title: "资格认证",
          login: true,
        },
        component: () => import("@/views/index/page/check.vue"),
      },
      {
        path: "/detail",
        name: "detail",
        meta: {
          title: "商品详情",
          login: true,
        },
        component: () => import("@/views/index/page/detail.vue"),
      },
      {
        path: "/detailIndex",
        name: "detail",
        meta: {
          title: "商品宣传详情",
          login: true,
        },
        component: () => import("@/views/index/page/detailIndex.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录",
    },
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/register/index.vue"),
    children: [
      {
        path: "/register",
        name: "register",
        meta: {
          title: "注册",
        },
        component: () => import("@/views/register/register.vue"),
      },
      {
        path: "/editsPassword",
        name: "editsPassword",
        meta: {
          title: "修改密码",
        },
        component: () => import("@/views/register/edits.vue"),
      },
      {
        path: "/success",
        name: "success",
        meta: {
          title: "注册成功",
        },
        component: () => import("@/views/register/success.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
