import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    Search: "",
    Part: "",
    Searchtime: "",
  },
  mutations: {
    change(state, payload) {
      if (payload === "test") {
        state.Search = "";
      } else {
        state.Search = payload;
        state.Searchtime = new Date().getTime();
      }
    },

    changePart(state, payload) {
      if (payload === "test") {
        state.Part = "";
      } else {
        state.Part = payload;
		state.Searchtime = new Date().getTime();
      }
    },
  },
  actions: {},
};
