<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";

export default {
  data() {
    return {
      locale: zhCN,
    };
  },
  created() {
    // 在页面加载时读取sessionStorage
    // console.log(sessionStorage.getItem("store"));
    if (
      sessionStorage.getItem("store") &&
      sessionStorage.getItem("store") !== "undefined"
    ) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }

    if (localStorage.getItem("token")) {
      this.$store.state.Public.Token = localStorage
        .getItem("token")
        .replace(/"/g, "");
    }
    // // 在页面刷新时将store保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      this.$store.state.Header.Search = "";
      this.$store.state.Header.Part = "";
      sessionStorage.setItem("store", JSON.stringify(this.$store.state.header));
    });
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
#nprogress .bar {
  background: #fe8432 !important;
}

/* 设置商品详情放大镜功能定位问题 */
.mouse-cover-canvas {
  position: absolute;
  width: 360px;
  left: calc((100% - 1200px) / 2 + 360px) !important;
  top: 10px !important;
  z-index: 999;
  display: none;
}
</style>
