import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    Token: "",
    Num: 0,
    firstStatus: true,
    passList: [],
  },
  mutations: {
    setToken(state, payload) {
      console.log("shezhitoken", payload);
      state.Token = payload;
      if (payload !== "") {
        state.Num++;
      } else {
        state.Num = 0;
      }
    },

    setStatus(state, payload) {
      state.firstStatus = payload;
    },
    // username: "",
    // password: "",
    remberPs(state, payload) {
      console.log("设置记住密码");
      let have = false;
      state.passList.map((i) => {
        if (i.username === payload.username) {
          i.password = payload.password;
          have = true;
        }
      });
      if (!have) {
        state.passList.push(payload);
        console.log(state.passList);
      }
    },
  },
  actions: {},
};
